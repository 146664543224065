////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(42, 54, 98);
}
.bg-thirdColor {
  background-color: rgb(42, 54, 98);
}
.bg-fourthColor {
  background-color: rgb(222, 71, 37);
}
.bg-fifthColor {
  background-color: rgb(42, 54, 98);
}
.bg-sixthColor {
  background-color: rgb(222, 71, 37);
}
.bg-seventhColor {
  background-color: rgb(42, 54, 98);
}
